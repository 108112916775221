/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useCallback, useEffect } from "react";
import { Menu, X, ArrowLeft } from "react-feather";
import { Navbar, Button, Text } from "@doar/components";
import { menuData } from "@doar/shared/data";
import Logo from "../../components/logo";
import { useAppDispatch, useAppSelector } from "src/redux/hooks";
import { toggleSidebar, toggleBody } from "src/redux/slices/ui";
import { getUnansweredCount } from "src/redux/slices/messages";
import { getUserInfo } from "src/redux/slices/global";
import { formatDistanceToNow, parseISO } from "date-fns";
import {
    StyledHeader,
    StyledLogo,
    StyledNavbarWrap,
    StyledNavbarMenu,
    StyledNavbarHeader,
    StyledNavbarTitle,
    StyledMenuBtn,
    StyledSidebarBtn,
    StyledTrialCounter,
    StyledBookCall
} from "./style";

interface IProps {
    hasSidebar?: boolean;
    sidebarLayout?: 1 | 2;
}

const Header = ({ hasSidebar, sidebarLayout }: IProps) => {
    const dispatch = useAppDispatch();
    const { sidebar, isBody } = useAppSelector((state) => state.ui);
    const messageCount = useAppSelector(getUnansweredCount);
    const { plan, trialEnd, userId, pms } = useAppSelector(getUserInfo);

    const [menuOpen, setMenuOpen] = useState(false);
    const sidebarHandler = useCallback(
        (_: any, isOpen?: "open") => {
            dispatch(toggleSidebar({ isOpen }));
        },
        [dispatch]
    );

    const bodyHandler = useCallback(() => {
        dispatch(toggleBody());
        dispatch(toggleSidebar({ isOpen: "open" }));
    }, [dispatch]);

    const menuHandler = useCallback(() => {
        setMenuOpen((prev) => !prev);
        if (menuOpen) {
            sidebarHandler(undefined, "open");
        }
    }, [menuOpen, sidebarHandler]);

    useEffect(() => {
        return () => {
            sidebarHandler(undefined, "open");
            bodyHandler();
        };
    }, [sidebarHandler, bodyHandler]);

    return (
        <>
            <StyledHeader>
                {hasSidebar && sidebarLayout === 1 && (
                    <>
                        <StyledMenuBtn
                            variant="texted"
                            onClick={menuHandler}
                            $hasSidebar={hasSidebar}
                            $sidebarOpen={sidebar}
                            $bodyOpen={isBody}
                            className="menu-btn"
                        >
                            <Menu size={20} strokeWidth="2.5px" />
                        </StyledMenuBtn>
                        <StyledSidebarBtn
                            variant="texted"
                            onClick={!isBody ? sidebarHandler : bodyHandler}
                            $sidebarOpen={sidebar}
                            $bodyOpen={isBody}
                            className="sidebar-btn"
                        >
                            <ArrowLeft size={20} strokeWidth="2.5px" />
                        </StyledSidebarBtn>
                    </>
                )}
                {hasSidebar && sidebarLayout === 2 && (
                    <>
                        <StyledMenuBtn
                            variant="texted"
                            onClick={menuHandler}
                            $hasSidebar={hasSidebar}
                            $sidebarOpen={!sidebar}
                            $bodyOpen={false}
                        >
                            <Menu size={20} strokeWidth="2.5px" />
                        </StyledMenuBtn>
                        <StyledSidebarBtn
                            variant="texted"
                            onClick={sidebarHandler}
                            $sidebarOpen={!sidebar}
                            $bodyOpen={false}
                        >
                            <ArrowLeft size={20} strokeWidth="2.5px" />
                        </StyledSidebarBtn>
                    </>
                )}
                {!hasSidebar && (
                    <StyledMenuBtn
                        variant="texted"
                        onClick={menuHandler}
                        $hasSidebar={hasSidebar}
                        $sidebarOpen={!sidebar}
                    >
                        <Menu size={20} strokeWidth="2.5px" />
                    </StyledMenuBtn>
                )}
                <StyledLogo>
                    <Logo />
                </StyledLogo>
                <StyledNavbarWrap
                    $isOpen={menuOpen}
                    onClick={menuHandler}
                    className="navbar-wrap"
                >
                    <StyledNavbarMenu
                        $isOpen={menuOpen}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <StyledNavbarHeader>
                            <Logo />
                            <Button variant="texted" onClick={menuHandler}>
                                <X
                                    color="#7987a1"
                                    width={20}
                                    strokeWidth="2.5px"
                                />
                            </Button>
                        </StyledNavbarHeader>
                        <StyledNavbarTitle>MAIN NAVIGATION</StyledNavbarTitle>
                        <Navbar userId={userId} menus={menuData} messageCount={messageCount} pms={(pms as "hostaway" | "guesty")} />
                    </StyledNavbarMenu>
                </StyledNavbarWrap>
                <StyledTrialCounter>
                    {
                        plan === "free" || plan === "free_forever" ? "Free plan" :
                        plan === "trial" || plan == "trial_with_card" ? (trialEnd ? `Free trial ends ${formatDistanceToNow(parseISO(trialEnd), { addSuffix: true }).replace('about ', '')}` : `Free trial`) :
                        plan === "paid" ? "Paid plan" : ""
                    }
                </StyledTrialCounter>

                <StyledBookCall>
                  <a onClick={() => window.open("https://calendly.com/samfrombesty/onboarding", "_blank")}>Call us</a>
                </StyledBookCall>
            </StyledHeader>
        </>
    );
};

Header.defaultProps = {
    sidebarLayout: 1,
};

export default Header;
