import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { WidgetSettings } from "src/api";

export interface GlobalState {
    widgetSettings: WidgetSettings;
}


const initialState: GlobalState = {
    widgetSettings: {
        enabled : false,
        dbsEngine : "irrelevant",
        collectInfoMode : "never",
        coolOffPeriod : 15,
        slackMode : "thread",
        bestyAIRefer : "Besty AI",
        hostRefer : "Host",
        welcomeMessage : `Ask me anything about any of our properties here for an **instant AI-powered response!** For example “*2 bedroom apartments by the beach available oct 15 - 19*"`
    },
};

const widgetSettingsSlice = createSlice({
    name: "widgetSettings",
    initialState,
    reducers: {
        setWidgetSettings: (state, action) => {
            state.widgetSettings = {
                ...state.widgetSettings,
                ...action.payload,
            };
        },
    },
});

export const { setWidgetSettings } = widgetSettingsSlice.actions;

export const getWidgetSettings = (state: RootState) =>
    state.widgetSettings.widgetSettings;

export default widgetSettingsSlice.reducer;
