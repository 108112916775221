import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/redux/store";
import { Listing, Settings } from "src/api";

interface ListingPref {
  id: number;
  listingIds: number[];
  preferences: Settings[];
};

interface timeRangePref {
  id: number;
  timeZone: string;
  timeStart: Date;
  timeEnd: Date;
  preferences: any[];
};

export interface GlobalState {
  mode: string;
  settings: Settings[];
  listingPrefs: ListingPref[];
  timeRangePrefs: timeRangePref[];
}

const initialState: GlobalState = {
  //settings: { phone: "", email: "", method: "" },
  mode: "normal", // 'normal', 'listings', 'time'
  settings: [], // Normal mode settings
  listingPrefs: [],
  timeRangePrefs: [],
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.mode = action.payload.mode ?? state.mode;
      state.settings = action.payload.settings ?? state.settings;
      state.listingPrefs = action.payload.listingPrefs ?? state.listingPrefs;
      state.timeRangePrefs = action.payload.timeRangePrefs ?? state.timeRangePrefs;
    },
    setMode: (state, action) => {
      state.mode = action.payload;
    },
    setListingPrefs: (state, action) => {
      state.listingPrefs = action.payload;
    },
    addNewListingPref: (state, action) => {
      state.listingPrefs.push(action.payload);
    },
    setTimeRangePrefs: (state, action) => {
      state.timeRangePrefs = action.payload;
    },
  },
});

export const { setSettings, setMode, setListingPrefs, setTimeRangePrefs } = settingsSlice.actions;

//export const getSettings = (state: RootState) => state.settings.settings || [];
export const getSettings = (state: RootState) => {
  return {
    mode: state.settings.mode,
    settings: state.settings.settings,
    listingPrefs: state.settings.listingPrefs,
    timeRangePrefs: state.settings.timeRangePrefs,
  };
};

export default settingsSlice.reducer;
